import React from 'react'
import { Helmet } from 'react-helmet'
import AddressForm, { Props } from '../components/MyAccount/AddressForm'
import secrets from '../config/secrets'
import withPageTransition from '../components/withPageTransition'

const AddressPage = (props: Props) => (
  <>
    <Helmet>
      <script src={secrets.GATSBY_GOOGLE_MAPS_URL} />
    </Helmet>
    <AddressForm {...props} />
  </>
)

export default withPageTransition(AddressPage)
