import styled from 'styled-components'
import { breakpoints } from '@ecommerce/shared'

export const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.grey};
  padding: 30px 0 106px;

  @media screen and (${breakpoints.tabletLandscape.max}) {
    padding: 0;
  }
`

export const PageInner = styled.div`
  max-width: 726px;
  min-height: 100vh;
  margin: auto;
  padding: 34px 92px 100px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.boxShadow.lvlTwo};
  position: relative;

  h1 {
    color: ${({ theme }) => theme.colors.black};
    font-size: 24px;
    text-align: center;
    margin: 0;
  }

  @media screen and (${breakpoints.tabletLandscape.max}) {
    max-width: 100%;
    width: 100%;
    padding: 25px 0 100px 0;
  }
`

export const BackButton = styled.button`
  position: absolute;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.black};
  top: 35px;
  left: 60px;
  background: none;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0;

  svg {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.colors.black};
    margin-right: 6px;
  }

  @media screen and (${breakpoints.tabletLandscape.max}) {
    display: none;
  }
`

export const Form = styled.form`
  margin: 50px auto 0;
  width: 336px;

  .address-form-input,
  .CitySelect {
    margin-bottom: 15px;
  }

  .house-number {
    margin-top: 30px;
  }

  .instructions {
    span {
      margin-bottom: 15px;
      font-size: 12px;
      display: block;
    }

    .notes-error-message {
      color: ${({ theme }) => theme.colors.error};
      font-size: 12px;

      svg {
        fill: ${({ theme }) => theme.colors.error};
      }
    }
  }

  .address-notes {
    height: 85px;
  }

  button[type='submit'] {
    width: 70%;
    margin: 50px auto 0 auto;
    display: block;
  }

  .favorite-input {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 42px;

    input[type='checkbox'] {
      margin: 0 8px 0 0;
    }

    span {
      font-size: 13px;
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .error-message {
    color: ${({ theme }) => theme.colors.error};
    font-weight: bold;
    justify-content: center;
    margin-top: 25px;

    svg {
      fill: ${({ theme }) => theme.colors.error};
    }
  }

  @media screen and (${breakpoints.tabletLandscape.max}) {
    margin-top: 30px;
  }
`
