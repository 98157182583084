import * as yup from 'yup'
import { WindowWithMaps } from '@ecommerce/shared'
import { validatePhone } from '../../Checkout/utils'

declare const window: WindowWithMaps

export const clearState = () => {
  // Set maps api to null to avoid unwanted side effects
  // when navigating between pages
  if (window.google?.maps) window.google.maps = null
  window.history.replaceState(null, '')
}

export const schema = yup.object().shape({
  market: yup.string().required('Debes seleccionar una ciudad'),
  shipping_address: yup.string().required('Debes seleccionar una dirección'),
  shipping_name: yup.string().required('Debes completar este campo'),
  shipping_phone: yup
    .string()
    .required('Debes completar este campo')
    .test('Phone Test', 'Debes ingresar un teléfono válido', (value) => validatePhone(value || '')),
  shipping_instructions: yup.string().max(200, (max) => `No debe exceder los ${max.max} caracteres`),
})
