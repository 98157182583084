import React, { useState, useEffect, useRef } from 'react'
import loadable from '@loadable/component'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
  TextField,
  TextArea,
  Button,
  useLocation,
  Select,
  InfoMessage,
  updateAddress,
  checkAddress,
  createUserAddress,
  FormattedAddress,
  Geocode,
  Coordinates,
  useAuth,
  useActivityDialog,
  useResolution,
  updateFavoriteAddress,
  getAuth,
  useDistricts,
} from '@ecommerce/shared'
import Layout from '../../Layout'
import { Icon } from '../../Icon/Icon'
import secrets from '../../../config/secrets'
import { CurrentCity, FlatLocationProductCategory } from '../../../types/PgPages'
import { schema, clearState } from './utils'
import { getNavBar } from '../CustomNavBar'
import { Wrapper, BackButton, PageInner, Form } from './Layout'
import AddressInput from '../AddressInput'
import { updateFavorites } from '../../Checkout/utils'
import { DEFAULT_GEOCODE } from '../../Checkout/components/MyDelivery/useMyDelivery'

const OutOfRangeModal = loadable(() => import('../../Checkout/components/MyDelivery/OutOfRangeModal'))

const { COUNTRY } = secrets

export type Props = {
  pageContext: CurrentCity & {
    productCategories?: FlatLocationProductCategory[]
  }
  location: {
    state: {
      action: 'edit-address' | 'new-address'
      isDefault: boolean
      editingAddress: FormattedAddress
      useCurrentCity: boolean
      addresses: FormattedAddress[]
    }
  }
}

const AddressForm = (props: Props) => {
  const { isDesktop } = useResolution()
  const {
    pageContext: { currentMarket, currentZone, productCategories },
    location: { state },
  } = props
  const { isDefault, editingAddress, useCurrentCity, addresses } = state ?? {}
  const {
    state: { ownerId, firstName, lastName },
  } = useAuth()
  const {
    state: { markets },
    textByCountry,
    isBolivia,
  } = useLocation()
  const isBO = isBolivia()

  const cities = Object.values(markets).map(({ name, internalName }) => ({ label: name, value: internalName }))

  const [selectedMarket, setSelectedMarket] = useState({
    name: currentMarket.name,
    internalName: currentMarket.internalName,
    id: currentMarket.id,
    map: currentZone.googleEmbedId,
    distributionCenters: currentMarket.distributionCenters,
  })
  const [geocode, setGeocode] = useState<Geocode | null>(null)
  const [clearAddress, setClearAddress] = useState(false)
  const [addressHasChanged, setAddressHasChanged] = useState(false)

  const addressRef = useRef<string>(editingAddress?.shipping_address)

  // Modal settings
  const { setTitle, setBody, onOpenModal, onCloseModal, ref } = useActivityDialog()
  // Regions settings
  const { districtState, setDistrictState } = useDistricts({
    market: currentMarket,
    editingAddress,
    context: 'my-addresses',
    markets,
  })

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const { register, setValue, errors, watch, handleSubmit, clearErrors, getValues } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'all',
  })
  const fields = watch()

  // Clear address input value if is out of range
  const clearAddresssInput = () => {
    // Clear values
    setValue('shipping_address', '')
    setClearAddress(true)
    setGeocode(null)

    onCloseModal()
  }

  // Previous page url
  const previousPath = `/mis-direcciones`

  useEffect(() => {
    if (!state || !getAuth()) {
      navigate(previousPath)
    }

    window.addEventListener('beforeunload', clearState)

    register({ name: 'market' })
    register({ name: 'shipping_address' })

    if (editingAddress) {
      setValue('shipping_address', editingAddress.shipping_address)
      setValue('market', editingAddress.marketName)

      // Update geocode
      const latitude = editingAddress?.geocode?.latitude
      const longitude = editingAddress?.geocode?.longitude
      if (latitude && longitude) {
        setGeocode({ latitude, longitude, country: COUNTRY })
      }

      // Set city
      const selected_market = Object.values(markets).filter(({ id }) => id === editingAddress.marketId)[0]
      const selected_zone = selected_market.zones.filter((z) => z.id === editingAddress.zoneId)[0]
      setDistrictState((prev) => ({ ...prev, selectedZone: selected_zone }))

      setSelectedMarket({
        name: selected_market.name,
        internalName: selected_market.internalName,
        id: selected_market.id,
        map: selected_zone.googleEmbedId,
        distributionCenters: selected_market.distributionCenters,
      })
    } else if (useCurrentCity) {
      setValue('market', selectedMarket.name)
      if (isBO) {
        const zone = markets.filter((mk) => mk.id === selectedMarket.id)[0].zones[0]
        setDistrictState((prev) => ({ ...prev, selectedZone: zone }))
      }
    }

    // Set modal content
    setTitle('Sin cobertura')
    setBody(<OutOfRangeModal mapID={selectedMarket.map} city={selectedMarket.name} onClose={clearAddresssInput} />)

    // Cleanup
    return () => clearState()
  }, [])

  const handleMarketSelect = (selected: string) => {
    if (selected !== selectedMarket.internalName) {
      setClearAddress(true)
      setValue('shipping_address', undefined)
    }
    const newMarket = Object.values(markets).filter((city) => selected === city.internalName)[0]
    if (isBO) {
      const zone = newMarket.zones[0]
      setDistrictState((prev) => ({ ...prev, selectedZone: zone }))
      addressRef.current = selected
    }

    setSelectedMarket({
      name: newMarket.name,
      internalName: newMarket.internalName,
      id: newMarket.id,
      map: selectedMarket.map,
      distributionCenters: newMarket.distributionCenters,
    })

    setValue('market', selected, { shouldValidate: true })
    setBody(<OutOfRangeModal mapID={selectedMarket.map} city={newMarket.name} onClose={clearAddresssInput} />)
  }

  const handleAddressSelect = (value: string, gcode?: Geocode) => {
    setValue('shipping_address', value, { shouldValidate: true })
    addressRef.current = value

    if (gcode) setGeocode(gcode)
    setAddressHasChanged(false)
    setClearAddress(false)
  }

  const handleAddressChange = (value: string) => {
    const hasChanged = value !== addressRef.current
    if (hasChanged) {
      setValue('shipping_address', '')
      setClearAddress(false)
    }

    setAddressHasChanged(hasChanged)
  }

  const handleMapClick = (value: string, gcode: Coordinates) => {
    setValue('shipping_address', value)
    clearErrors('shipping_address')
    setGeocode({ latitude: gcode.lat, longitude: gcode.lng, country: COUNTRY })
    setAddressHasChanged(false)
  }

  const onFormSubmit = async () => {
    if (!isLoading) {
      try {
        // Check region
        if (districtState.checkZone && !districtState.selectedZone) {
          return setDistrictState((prev) => ({ ...prev, hasError: true }))
        }

        // Show loader
        setLoading(true)

        // Hide errors
        setError(false)
        setClearAddress(false)

        let addressID = ''

        // Check address
        let isInRange = false
        const marketId = selectedMarket.id
        const zoneId = districtState.selectedZone?.id ?? 0

        if (geocode) {
          isInRange = await checkAddress({
            lat: geocode.latitude,
            long: geocode.longitude,
            marketId,
            zoneId,
            country: COUNTRY,
          })
        }

        const distributionCenter = selectedMarket.distributionCenters.filter(
          (dc) => dc.id === districtState.selectedZone?.distributionCenterId,
        )[0]

        if (!isInRange) {
          onOpenModal()
        } else {
          if (editingAddress) {
            await updateAddress({
              ownerId,
              id: editingAddress?.id,
              attributes: {
                city: distributionCenter.internalName,
                first_name: firstName,
                last_name: lastName,
                full_name: `${firstName} ${lastName}`,
                country_code: COUNTRY,
                state_code: selectedMarket.id.toString(),
                line_1: fields.shipping_address ?? editingAddress?.shipping_address,
                line_2: fields.shipping_number ?? editingAddress?.shipping_number,
                phone: fields.shipping_phone ?? editingAddress?.shipping_phone,
                notes: fields.shipping_instructions ?? editingAddress?.shipping_instructions,
                lat: geocode?.latitude ?? editingAddress.geocode.latitude ?? DEFAULT_GEOCODE[COUNTRY].lat,
                lng: geocode?.longitude ?? editingAddress.geocode.longitude ?? DEFAULT_GEOCODE[COUNTRY].lng,
                zip_code: districtState.selectedZone?.name ?? editingAddress.zoneName,
                metadata: {
                  favorite: editingAddress?.isFavorite ?? false,
                  alias: fields.shipping_name ?? editingAddress?.shipping_name,
                  marketId: selectedMarket.id,
                  marketName: selectedMarket.name,
                  zoneId: districtState.selectedZone?.id ?? editingAddress.zoneId,
                  zoneName: districtState.selectedZone?.name ?? editingAddress.zoneName,
                },
              },
            })
          } else {
            const newAddress = await createUserAddress({
              ownerId,
              id: 'ID',
              attributes: {
                city: distributionCenter.internalName,
                first_name: firstName,
                last_name: lastName,
                full_name: `${firstName} ${lastName}`,
                country_code: COUNTRY,
                state_code: selectedMarket.id.toString(),
                line_1: fields.shipping_address,
                line_2: fields.shipping_number,
                phone: fields.shipping_phone,
                notes: fields.shipping_instructions,
                lat: geocode?.latitude ?? DEFAULT_GEOCODE[COUNTRY].lat,
                lng: geocode?.longitude ?? DEFAULT_GEOCODE[COUNTRY].lng,
                zip_code: districtState.selectedZone?.name ?? selectedMarket.name,
                metadata: {
                  favorite: false,
                  alias: fields.shipping_name,
                  marketId: selectedMarket.id,
                  marketName: selectedMarket.name,
                  zoneId: districtState.selectedZone?.id ?? selectedMarket.id,
                  zoneName: districtState.selectedZone?.name ?? selectedMarket.name,
                },
              },
            })

            addressID = newAddress.id
          }

          if (fields.favorite) {
            const id = editingAddress ? editingAddress.id : addressID
            const newFavorites = updateFavorites(addresses, true, id)

            updateFavoriteAddress(newFavorites)
          }

          setTimeout(() => navigate(previousPath), 800)
        }
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }
  }

  const canSave =
    fields.city !== '' &&
    fields.shipping_address !== '' &&
    fields.shipping_phone !== '' &&
    fields.shipping_name !== '' &&
    geocode !== null &&
    !addressHasChanged &&
    !errors.shipping_instructions
  const selectPlaceholder = useCurrentCity ? currentMarket.name : editingAddress?.marketName

  const NavbarWithProp = () =>
    getNavBar({
      slug: previousPath,
      onLogoClick: () => navigate('/'),
      currentMarket,
      categories: productCategories,
    })

  return (
    <Layout
      title={`${state?.action === 'edit-address' ? 'Editar' : 'Añadir'} Direccion`}
      navbar={isDesktop ? undefined : NavbarWithProp}
    >
      <Wrapper ref={ref}>
        <PageInner>
          <BackButton onClick={() => navigate(previousPath)}>
            <Icon iconId="arrow_left" />
            Volver
          </BackButton>
          <h1>Datos de nueva dirección</h1>
          <Form onSubmit={handleSubmit(onFormSubmit)}>
            <Select
              name="market"
              className="CitySelect"
              label={useCurrentCity ? 'Seleccionar región' : ''}
              placeholder={selectPlaceholder ?? 'Seleccionar región'}
              options={cities}
              onSelect={handleMarketSelect}
              status={!errors?.market ? undefined : 'error'}
              errorMessage={errors?.market?.message}
            />
            <AddressInput
              country={COUNTRY}
              market={selectedMarket.name}
              markets={markets}
              onInputChange={handleAddressChange}
              onSelect={handleAddressSelect}
              status={!errors?.shipping_address ? undefined : 'error'}
              errorMessage={errors?.shipping_address?.message}
              defaultValue={editingAddress?.shipping_address}
              defaultGeocode={editingAddress?.geocode}
              districtState={{ state: districtState, dispatcher: setDistrictState }}
              onMapClick={handleMapClick}
              shouldClear={clearAddress}
              isBO={isBO}
            />
            <TextField
              maxLength={isBO ? 50 : undefined}
              className="address-form-input house-number"
              ref={register}
              type="text"
              name="shipping_number"
              defaultValue={editingAddress?.shipping_number}
              label="Depto. / Casa Condominio (Opcional)"
              placeholder="Depto. 27"
            />
            <TextField
              maxLength={isBO ? 50 : undefined}
              className="address-form-input"
              type="text"
              ref={register}
              name="shipping_name"
              defaultValue={editingAddress?.shipping_name}
              label="Nombre etiqueta"
              placeholder="Ej: Casa"
              status={!errors?.shipping_name ? undefined : 'error'}
              errorMessage={errors?.shipping_name?.message}
            />
            <TextField
              maxLength={isBO ? 20 : undefined}
              className="address-form-input"
              type="tel"
              ref={register}
              name="shipping_phone"
              defaultValue={editingAddress?.shipping_phone}
              label="Teléfono de contacto"
              placeholder={textByCountry('+56923456789', '23456789')}
              status={!errors?.shipping_phone ? undefined : 'error'}
              errorMessage={errors?.shipping_phone?.message}
            />
            <TextArea
              label="Agrega instrucciones especiales para tu entrega: (Opcional)"
              className="address-notes"
              name="shipping_instructions"
              defaultValue={editingAddress?.shipping_instructions}
              ref={register}
              placeholder="(Ej: Dejar en conserjería, tocar timbre para abrir portón, etc.)"
              maxLength={200}
            />
            {/*
             DESHABILITADO TEMPORALMENTE
            <div className="favorite-input">
              <Checkbox defaultChecked={edittingAddress?.isFavorite} ref={register} name="favorite" />
              <span>Usar esta dirección como favorita</span>
            </div> */}
            <Button isLoading={isLoading} isDisabled={!canSave} type="submit">
              Guardar
            </Button>
            <InfoMessage
              className="error-message"
              isHidden={!error}
              message="Error en la conexión, Intenta de nuevo por favor."
            />
          </Form>
        </PageInner>
      </Wrapper>
    </Layout>
  )
}

export default AddressForm
